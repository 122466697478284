import { graphql, PageProps } from 'gatsby'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../specific/constants/menu'
import Layout from '../../components/Layout'
import React, { useEffect, useState } from 'react'
import { locale, t } from '../../i18n'
import route from '../../utils/route'
import { productoActivo } from '../../components/FichasNeumaticos/utils'
import CatalogoFicha from '../../../shared/catalogo/producto/ficha/CatalogoFicha'
import RequestButton from '../../../shared/catalogo/producto/ficha/RequestButton'
import { loadCategoriaIcon } from '../../../shared/catalogo/producto/ficha/gallery/utils'
import _ from 'lodash'
import ComprarProductoCTA from '../../../shared/catalogo/producto/ficha/cta/ComprarProductoCTA'
import { initiaBateriaOptions } from '../../components/cesta/cestaItem/CestaItem'
import Montaje from '../../../shared/catalogo/producto/ficha/cta/Montaje'
import { generateBateriaTags } from '../../../coche/baterias/catalogo/generateBateriaTags'
import useUpdatePromocion from '../../helpers/customHooks/useUpdatePromocion'
import { getFetchFunction } from '../../../shared/catalogo/producto/ficha/promocion/getFetchFunction'
import { ProductInfo } from '../../context/actions/cestaActions'
import WhiteBoxContainer from '../../../shared/components/WhiteBoxContainer'
import RequestTyre from '../../components/RequestTyre/RequestTyre'
import { CatalogoProductInfo } from '../../../shared/catalogo/producto/ficha/CatalogoProductInfo'
import Modal from '../../../shared/components/Modal/Modal'

const FichaBateriaPage = ({
  data: { bateriaInfo, seoData },
}: PageProps<Queries.FichaBateriaPageQuery>) => {
  useSelectedMenu(MenuPrincipal.COCHE)

  const [successMessage, setSuccessMessage] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedProducto, setSelectedProducto] = useState(
    null as ProductInfo | null
  )

  const isActive = true
  const [icon, setIcon] = useState(null)

  useEffect(() => {
    if (!bateriaInfo) {
      return
    }
    loadCategoriaIcon(bateriaInfo.producto.categoria).then(setIcon)
  }, [bateriaInfo])

  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const updatedBateriaInfo = {
    ...bateriaInfo,
    producto: {
      ...bateriaInfo.producto,
      [`descripcion_${locale}`]: t('baterias.ficha.bateria_de_coche'),
      disponibilidad: productoActivo(bateriaInfo.producto),
    },
  }

  const catalogoProductInfo: CatalogoProductInfo = {
    ...updatedBateriaInfo,
    nombre_producto: bateriaInfo[`nombre_producto_${locale}`],
    estaciones: icon ? [icon] : [],
    productInfo: {
      id: bateriaInfo.producto.id_navision,
      type: 'bateria',
    },
  }

  const updatedPromotion = useUpdatePromocion({
    id_site_marca_bateria: bateriaInfo.id_site_marca_bateria,
    fetchFunction: getFetchFunction('bateria'),
  })

  const CTAButton = isActive ? (
    <ComprarProductoCTA
      catalogoProductInfo={catalogoProductInfo}
      options={initiaBateriaOptions}
      montaje={
        <Montaje
          servicios={bateriaInfo.producto.servicio_montaje.filter((servicio) =>
            servicio?.id_navision?.toLowerCase().includes('montaje')
          )}
        />
      }
      showGarantia={true}
      bannerPromocion={updatedPromotion}
    />
  ) : (
    <section>
      <WhiteBoxContainer className={'yellow'}>
        <RequestButton
          productInfo={catalogoProductInfo.productInfo}
          openModal={setShowModal}
          selectProduct={setSelectedProducto}
          flexDirection={'column'}></RequestButton>
      </WhiteBoxContainer>
    </section>
  )

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.coche'),
          link: route('coche.index'),
        },
        {
          display: t('bread_crumb.baterias'),
          link: route('baterias.index'),
        },
      ]}>
      <CatalogoFicha
        catalogoProductInfo={catalogoProductInfo}
        specifications={[
          {
            label: t('baterias.ficha.marca'),
            value: bateriaInfo.marca.nombre,
          },
          {
            label: t('baterias.ficha.modelo'),
            value: _.startCase(
              _.toLower(bateriaInfo[`nombre_producto_${locale}`])
            ),
          },
          {
            label: t('baterias.ficha.tipo'),
            value: _.toUpper(t(`baterias.filters.${bateriaInfo.tipo_bateria}`)),
          },
          {
            label: t('baterias.ficha.start-stop'),
            value: bateriaInfo.start_stop
              ? t('baterias.ficha.start-stop_si')
              : t('baterias.ficha.start-stop_no'),
          },
          {
            label: t('baterias.ficha.dimensiones'),
            value: bateriaInfo.dimensiones,
          },
          {
            label: t('baterias.ficha.bornes'),
            value: bateriaInfo.esquema_bornes,
          },
          {
            label: t('baterias.ficha.terminal'),
            value:
              bateriaInfo.posicion_positivo !== 'sin'
                ? t('baterias.ficha.terminal_positivo') +
                  ' ' +
                  t(`baterias.ficha.terminal_${bateriaInfo.posicion_positivo}`)
                : null,
          },
          {
            label: t('baterias.ficha.tension'),
            value: `${bateriaInfo.voltaje} V`,
          },
          {
            label: t('baterias.ficha.potencia_arranque'),
            value: `${bateriaInfo.potencia_arranque} A`,
          },
          {
            label: t('baterias.ficha.amperaje'),
            value: `${bateriaInfo.amperaje} Ah`,
          },
        ]}
        cta={CTAButton}
        tags={generateBateriaTags(bateriaInfo)}
      />
      <Modal
        title={t('landing_neumaticos.neumatico_item.disponibilidad')}
        closeModal={closeModal}
        showModal={showModal}>
        <RequestTyre
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedNeumatico={selectedProducto}
          closeModal={closeModal}
        />
      </Modal>
    </Layout>
  )
}
export const pageQuery = graphql`
  query FichaBateriaPage($idSiteProductosBaterias: Int!, $url: String!) {
    bateriaInfo: bateria(
      id_site_productos_baterias: { eq: $idSiteProductosBaterias }
    ) {
      ...bateriaInfo
      ...productoBateriaInfo
      ...bateriaImagen
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
export default FichaBateriaPage
