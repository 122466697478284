import React from 'react'
import Button from '../../../../common/components/buttons/Button'
import { t } from '../../../../common/i18n'
import { ProductInfo } from '../../../../common/context/actions/cestaActions'
import styles from './cta/cta.module.scss'

interface Props {
  productInfo: ProductInfo
  selectProduct: (selected: ProductInfo) => void
  openModal: (open: boolean) => void
  flexDirection?: 'column' | 'row'
}

const RequestButton: React.FC<Props> = ({
  productInfo,
  selectProduct,
  openModal,
  flexDirection = 'column',
}) => {
  const onClickRequest = () => {
    selectProduct(productInfo)
    openModal(true)
  }
  return (
    <div
      className={`${styles.wrapper} ${
        flexDirection === 'column' ? styles.wrapper_col : styles.wrapper_row
      }`}>
      <div className={`${styles.no_dispo_header} ${styles.price_header_model}`}>
        <span className={styles.no_disponible}>
          {t('catalogo.producto.ficha.no_disponible')}
        </span>
      </div>
      <Button buttonClass={'no_disponible_button'} onClick={onClickRequest}>
        {t('catalogo.producto.ficha.recibir_alerta')}
      </Button>
    </div>
  )
}

export default RequestButton
