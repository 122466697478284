import { Tag } from '../../../shared/catalogo/producto/ficha/Tags/Tags'
import { t } from '../../../common/i18n'
import { BateriasInfo } from '../type/bateriaInfo'
import _ from 'lodash'

export const generateBateriaTags = (bateria: BateriasInfo): Tag[] => {
  return [
    bateria.start_stop && {
      label: _.toUpper(t('baterias.ficha.start-stop')),
      tooltip: t('baterias.ficha.tags.tooltip_start_stop'),
    },
    bateria.amperaje && {
      label: `${bateria.amperaje} Ah`,
      tooltip: t('baterias.ficha.tags.tooltip_amperaje', {
        amperaje: `${bateria.amperaje} Ah`,
      }),
    },
    bateria.potencia_arranque && {
      label: `${bateria.potencia_arranque} A`,
      tooltip: t('baterias.ficha.tags.tooltip_potencia', {
        potencia: `${bateria.potencia_arranque} A`,
      }),
    },
    bateria.tipo_bateria && {
      label: _.toUpper(bateria.tipo_bateria),
      tooltip: t('baterias.ficha.tags.tooltip_tipo', {
        tipo: bateria.tipo_bateria,
      }),
    },
  ].filter(Boolean)
}
